define(['lodash', 'warmupUtils'], function (_, warmupUtils) {
    'use strict';

    let filesData = []; // eslint-disable-line santa/no-module-state
    let uploading = false; //eslint-disable-line santa/no-module-state
    const mediaTypesTranslation = {
        Image: 'picture',
        Document: 'document',
        Video: 'video'
    };

    function uploadFileToMediaPlatform(fileUploadData, upload_token, upload_url) {
        const mediaType = mediaTypesTranslation[fileUploadData.fileType];
        const formData = new FormData();

        formData.append('upload_token', upload_token);
        formData.append('media_type', mediaType);
        formData.append('file', fileUploadData.file);
        formData.append('parent_folder_id', 'visitor-uploads');
        
        const ajaxObj = {
            type: 'POST',
            url: upload_url,
            success: response => {
                uploadNextFileOrFinish(fileUploadData, response);
            },
            error: err => {
                failUpload(fileUploadData, err);
            },
            data: formData,
            processData: false,
            contentType: false
        };

        warmupUtils.ajaxLibrary.ajax(ajaxObj);
    }

    function getUploadUrlAndSendFile(fileUploadData) {
        const mediaType = mediaTypesTranslation[fileUploadData.fileType];    
        const url = `${'https://files.wix.com/site/media/files/upload/url?media_type='}${mediaType}`;

        const ajaxObj = {
            type: 'GET',
            url,
            success: response => {
                uploadFileToMediaPlatform(fileUploadData, response.upload_token, response.upload_url);
            },
            error: err => {
                failUpload(fileUploadData, err);
            },
            headers: {
                Authorization: fileUploadData.authorizationToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };

        warmupUtils.ajaxLibrary.ajax(ajaxObj);
    }

    function uploadNextFileOrFinish(fileUploadData, response) {
        if (filesData.length === 0) {
            uploading = false;
            warmupUtils.loggingUtils.fedopsLogger.interactionEnded(warmupUtils.loggingUtils.fedopsLogger.INTERACTIONS.USER_FILE_UPLOAD.END);
            fileUploadData.onSuccess(response);
            return;
        }

        fileUploadData.onSuccess(response);
        startUploadFile();
    }

    function startUploadFile() {
        const fileUploadData = _.pullAt(filesData, 0)[0];

        uploading = true;
        getUploadUrlAndSendFile(fileUploadData);
    }

    function failUpload(fileUploadData, err) {
        uploading = false;
        fileUploadData.onFail(JSON.parse(err.response));
    }


    function uploadFile({file, authToken, fileType, uploadCompleteCB, uploadFailedCB}) {
        warmupUtils.loggingUtils.fedopsLogger.interactionStarted(warmupUtils.loggingUtils.fedopsLogger.INTERACTIONS.USER_FILE_UPLOAD.START);
        if (!authToken) {
            throw new Error('No upload authorization token given!');
        }
    
        filesData = filesData.concat({
            file,
            authorizationToken: authToken,
            fileType,
            onSuccess: uploadCompleteCB,
            onFail: uploadFailedCB
        });

        if (!uploading) {
            startUploadFile();
        }
    }

    return {
        uploadFile
    };
});
